<template>
  <div class="bs-border-box d-flex flex-justify-center flex-wrap">
    <!-- Error when no transit port exists for this metro/country -->
    <el-alert v-if="showNoTransitPortError"
      :title="$t('connections.no-transit-port-title')"
      :closable="false"
      type="error">
      {{ $t('connections.no-transit-port-message') }}

      <el-form-item prop="companySelectedUid"
        :show-message="false"
        class="d-none" />
    </el-alert>

    <!-- Left column -->
    <div v-else-if="!isTransitOrPrivateConnection"
      class="content-column">
      <div>
        <!-- If a specific company outside the default CSPs was chosen on the quick connect panel in the services page -->
        <div v-if="showCompany">
          <!-- Company Logo -->
          <img :src="showCompany._logo"
            :alt="$t('images.marketplace-logo')"
            class="width-50-pc ml-25-pc"><br>

          <!-- Company Name -->
          <strong>{{ showCompany.companyName }}</strong>

          <!-- Company Description -->
          <p class="note">
            {{ (showCompany.companyBio || $t('target-select.no-bio')).split('.')[0] }}.
            <i18n path="target-select.marketplace-html"
              tag="span"
              class="mt-2">
              <template #link>
                <a :href="`/marketplace/${slug(showCompany.companyName)}`"
                  target="_blank">{{ $t('menu.marketplace-profile') }}</a>
              </template>
            </i18n>
          </p>

          <el-row>
            <!-- Company Website -->
            <el-col v-if="showCompany.www"
              :span="12">
              <strong>{{ $t('general.website') }}</strong>
              <br>
              <a :href="showCompany.www"
                target="_blank"
                rel="nofollow">{{ $t('general.click-to-visit') }}</a>
            </el-col>
            <!-- Company Phone Number -->
            <el-col v-if="showCompany.phone"
              :span="12">
              <strong>{{ $t('general.phone') }}</strong>
              <br> {{ showCompany.phone }}
            </el-col>
          </el-row>
        </div>
        <div v-else>
          <el-form-item :label="$t('connections.select-provider')"
            prop="companySelectedUid">
            <!-- Provider Search Box -->
            <div v-if="availableCompanies.length > 10 || textFilter.length > 0">
              <el-input v-model="textFilter"
                :placeholder="$t('general.search')"
                class="dont-color flex-1"
                clearable
                @blur="trackSearch">
                <template #prefix>
                  <i class="fas fa-search halfway ml-4px"
                    aria-hidden="true" />
                </template>
              </el-input>
            </div>
            <!-- Provider List Table -->
            <div class="provider-select-holder">
              <el-table ref="providerSelectTable"
                :data="availableCompanies"
                highlight-current-row
                max-height="450"
                :show-header="true"
                :empty-text="$t('connections.no-matching-providers')"
                row-class-name="provider-row"
                class="provider-select-table full-width collapsed-border hide-table-header"
                @row-click="handleProviderSelect">
                <el-table-column property="company"
                  :label="$t('menu.company')"
                  min-width="100">
                  <template #default="{row}">
                    <div class="provider-cell"
                      data-testid="provider-cell">
                      <!-- Provider Logo -->
                      <img :src="row.companyImage"
                        :alt="`${row.companyName} ${$t('images.company-logo')}`"
                        class="mr-1 width-50px"
                        @error="setFallbackImage($event, missingMpIcon)">
                      <div>
                        <!-- Provider Name -->
                        <div class="provider-header">
                          {{ row.companyName }}
                        </div>
                        <!-- Provider Port Count -->
                        <div v-if="row.ports.length"
                          v-html="providerPortCountString(row.companyUid, row.ports)" /> <!-- eslint-disable-line vue/no-v-html -->
                      </div>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-form-item>
        </div>
      </div>
    </div>

    <!-- Right column -->
    <div class="content-column">
      <div>
        <div v-if="showAwsHeader">
          <div class="flex-row-centered">
            <h4>{{ $t('aws.connection-type') }}</h4>
            <el-tooltip placement="top"
              :content="showAwsTypeDetails ? $t('target-select.hide-info') : $t('target-select.show-info')"
              :open-delay="500">
              <el-button class="info-button"
                size="small"
                @click="toggleAwsInfoPanel">
                <i class="fal fa-chevron-up color-info expand-arrow"
                  :class="showAwsTypeDetails ? '' : 'active'"
                  aria-hidden="true" />
              </el-button>
            </el-tooltip>
          </div>
          <el-collapse-transition>
            <div v-if="showAwsTypeDetails">
              <hr>
              <div class="types-details">
                <h4>{{ $t('aws.hosted-vif') }}</h4>
                <p>{{ $t('aws.hosted-vif-description') }}</p>
                <p>{{ $t('aws.key-features') }}</p>
                <ul>
                  <li v-for="i in 5"
                    :key="i">
                    {{ $t(`aws.aws-vif-feature-list-${i}`) }}
                  </li>
                </ul>
                <h4>{{ $t('aws.hosted-connection') }}</h4>
                <p>{{ $t('aws.hosted-connection-description') }}</p>
                <p>{{ $t('aws.key-features') }}</p>
                <ul>
                  <li v-for="i in 5"
                    :key="i">
                    {{ $t(`aws.aws-hosted-feature-list-${i}`) }}
                  </li>
                </ul>

                <template v-if="!disabledFeatures.knowledgeBase">
                  <h4>{{ $t('general.documentation') }}</h4>
                  <i18n path="aws.documentation-html"
                    tag="p">
                    <template #link>
                      <a href="https://docs.megaport.com/cloud/megaport/aws/"
                        target="_blank">{{ $t('aws.documentation-link') }}</a>
                    </template>
                  </i18n>
                </template>
              </div>
              <hr>
              <h4 class="text-align-center">
                {{ $t('connections.select-connection-type') }}
              </h4>
            </div>
          </el-collapse-transition>

          <el-form-item ref="awsTypeFormItem"
            prop="serviceObj.bEnd.awsType">
            <div class="flex-row-centered flex-justify-center">
              <el-radio-group ref="awsType"
                v-model="destinationPortSettings.awsType"
                name="awsType"
                data-testid="awsType"
                @change="resetSelectedPortOnAwsTypeChange">
                <el-radio-button label="AWS"
                  data-name="awsType-AWS"
                  data-testid="awsType-AWS">
                  {{ $t('aws.hosted-vif') }}
                </el-radio-button>
                <el-radio-button label="AWSHC"
                  data-name="awsType-AWSHC"
                  data-testid="awsType-AWSHC">
                  {{ $t('aws.hosted-connection') }}
                </el-radio-button>
              </el-radio-group>
            </div>
          </el-form-item>
        </div>

        <div v-if="showPortSelectList">
          <el-collapse-transition>
            <div v-if="companySelected">
              <el-form-item :label="$t(['mx', 'private'].includes(destinationType) ? 'connections.select-destination' : 'connections.select-destination-port-title')"
                prop="serviceObj.bEnd.productUid">
                <div class="d-flex full-width gap-0-5">
                  <!-- Country Filter -->
                  <el-select v-if="companySelected && countries.length > 1"
                    v-model="countryFilter"
                    data-name="cloud-destination-port-filter"
                    clearable
                    class="dont-color flex-1"
                    :placeholder="$t('general.country-filter')"
                    name="countryFilter">
                    <el-option v-for="country in countries"
                      :key="country"
                      :value="country"
                      :data-port-country="country">
                      {{ country }}
                    </el-option>
                  </el-select>

                  <!-- Text Search - by product name, location details -->
                  <el-input v-if="destinationType === 'private'"
                    v-model="searchText"
                    :placeholder="$t('general.search')"
                    class="dont-color flex-1"
                    clearable>
                    <template #prefix>
                      <i class="fas fa-search ml-4px" />
                    </template>
                  </el-input>
                </div>

                <!-- Diversity Zone Filter -->
                <div v-if="showZoneFilter"
                  class="flex-row-centered zone-table-header">
                  <!-- Diversity Zone Filter Label -->
                  <span class="d-block">{{ $t('services.diversity-zone') }}:</span>

                  <!-- Diversity Zone Filter Options -->
                  <el-radio-group v-model="zoneFilter"
                    size="mini">
                    <!-- Diversity Zone Filter Option: All -->
                    <el-radio-button :label="null">
                      {{ $t('general.all') }}
                    </el-radio-button>
                    <!-- Diversity Zone Filter Option: Red -->
                    <el-radio-button :label="G_DIVERSITY_ZONE_RED"
                      class="dz-dot">
                      <i class="far fa-dot-circle diversity-icon diversity-color-red"
                        :title="$t(`services.red-zone`)" />
                    </el-radio-button>
                    <!-- Diversity Zone Filter Option: Blue -->
                    <el-radio-button :label="G_DIVERSITY_ZONE_BLUE"
                      class="dz-dot">
                      <i class="far fa-dot-circle diversity-icon diversity-color-blue"
                        :title="$t(`services.blue-zone`)" />
                    </el-radio-button>
                  </el-radio-group>

                  <div v-if="destinationPortSettings.awsType === 'AWSHC'">
                    <el-tooltip placement="top"
                      :open-delay="500">
                      <template #content>
                        <p class="tooltip-p">
                          {{ $t('connections.aws-diversity-tooltip') }}
                        </p>
                        <i18n v-if="!disabledFeatures.knowledgeBase"
                          path="aws.diversity-html"
                          tag="p"
                          class="tooltip-p">
                          <template #link>
                            <a href="https://docs.megaport.com/cloud/megaport/aws/aws-diversity/"
                              target="_blank"
                              class="el-button el-button--default el-button--mini">{{ $t('general.documentation') }}</a>
                          </template>
                        </i18n>
                      </template>
                      <i class="fas fa-question-circle color-info"
                        aria-hidden="true" />
                    </el-tooltip>
                  </div>
                </div>

                <div class="port-select-holder">
                  <el-table ref="portSelectTable"
                    :data="companySelected.portsFiltered"
                    :max-height="portSelectHeight"
                    :show-header="true"
                    :empty-text="$t('connections.no-matching-ports')"
                    highlight-current-row
                    row-key="productUid"
                    row-class-name="port-row"
                    class="port-select-table full-width collapsed-border hide-table-header"
                    data-name="cloud-destination-port-list"
                    @row-click="handlePortSelect">
                    <el-table-column property="port"
                      :label="$t('productNames.port')"
                      reserve-selection
                      min-width="100">
                      <template #default="{row}">
                        <div class="port-cell"
                          data-testid="port-cell"
                          :data-service-name="destinationType === 'mx' ? row._marketplaceTitle || row.title : row.title"
                          :data-location="row._location && row._location.formatted && row._location.formatted.short"
                          :data-diversity-zone="row.diversityZone || 'none'">
                          <mu-mega-icon :icon="iconName(row)"
                            class="icon" />
                          <div>
                            <div class="port-header">
                              {{ destinationType === 'mx' ? row._marketplaceTitle || row.title : row.title }}
                            </div>
                            <div v-if="row._location && row._location.formatted"
                              :data-name="row._location.formatted.short">
                              {{ row._location.formatted.short }}
                            </div>
                          </div>
                          <template v-if="row.diversityZone">
                            <div v-if="row.connectType === 'AWSHC'"
                              class="ml-auto">
                              <el-tooltip placement="top"
                                :content="$t('connections.aws-diversity-tooltip')"
                                :open-delay="500">
                                <i class="far fa-dot-circle diversity-icon"
                                  :class="`diversity-color-${row.diversityZone}`"
                                  aria-hidden="true" />
                              </el-tooltip>
                            </div>
                            <div v-else
                              class="ml-auto">
                              <i class="far fa-dot-circle diversity-icon"
                                :class="`diversity-color-${row.diversityZone}`"
                                :title="$t(`services.${row.diversityZone}-zone`)" />
                            </div>
                          </template>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </el-form-item>
            </div>
          </el-collapse-transition>
        </div>

        <div v-if="destinationType === 'private'">
          <el-collapse-transition>
            <el-row v-if="portObj.productUid"
              :gutter="8">
              <el-col :span="12"
                class="font-weight-500 text-align-right">
                {{ $t('services.service-id') }}
              </el-col>
              <el-col :span="12">
                #{{ portObj._subUid }}
              </el-col>
            </el-row>
          </el-collapse-transition>
        </div>

        <azure-key v-if="companyConnectType === 'AZURE'"
          :b-end="destinationPortSettings"
          :a-end="aEnd"
          @update="update" />

        <oracle-key v-else-if="companyConnectType === 'ORACLE'"
          :b-end="destinationPortSettings"
          @update="update" />

        <service-key v-else-if="companyConnectType === 'SAP'"
          :a-end="aEnd"
          :value="destinationPortSettings"
          @input="update" />

        <service-key v-else-if="companyConnectType === 'WASABI' && destinationType === 'cloud'"
          :a-end="aEnd"
          :value="destinationPortSettings"
          @input="update" />

        <gci-destinations v-else-if="companyConnectType === 'GCI' || companyConnectType === 'GOOGLE'"
          :a-end="aEnd"
          :b-end="destinationPortSettings"
          :ports="companySelected ? companySelected.ports : []"
          @update="update" />

        <nutanix-key v-else-if="companyConnectType === 'NUTANIX'"
          :a-end="aEnd"
          :b-end="destinationPortSettings"
          :ports="companySelected ? companySelected.ports : null"
          @update="update" />
      </div>
    </div>
  </div>
</template>

<script>
// External tools
import { mapState, mapGetters, mapMutations } from 'vuex'
import sdk from '@megaport/api-sdk'
// Internal tools
import captureSentryError from '@/utils/CaptureSentryError.js'
import { slug, deepClone } from '@/helpers.js'
import { setFallbackImage } from '@/utils/fallbackImage'
// Components
import AzureKeyComponent from '@/components/connection-extras/AzureKey.vue'
import OracleKeyComponent from '@/components/connection-extras/OracleKey.vue'
import GciDestinationsComponent from '@/components/connection-extras/GciDestinations.vue'
import NutanixKeyComponent from '@/components/connection-extras/NutanixKey.vue'
import TargetServiceKey from '@/components/connection-extras/TargetServiceKey.vue'
// Integrations
import { captureEvent } from '@/utils/analyticUtils'
// Globals
import { CLOUD_ITEMS, SAP_DETAILS, WASABI_DETAILS, AWS_COMPANY } from '@/Globals.js'

export default {
  name: 'TargetSelect',

  components: {
    'azure-key': AzureKeyComponent,
    'oracle-key': OracleKeyComponent,
    'gci-destinations': GciDestinationsComponent,
    'nutanix-key': NutanixKeyComponent,
    'service-key': TargetServiceKey,
  },

  inject: ['disabledFeatures'],

  // companySelectedUid and bEnd are synced properties, so emit update events on change.
  // Since bEnd is an object, it is cloned on initialization.
  props: {
    destinationType: {
      type: String,
      required: true,
    },
    companySelectedUid: {
      type: String,
      required: false,
      default: null,
    },
    aEnd: {
      type: Object,
      required: false,
      default() {
        return {}
      },
    },
    bEnd: {
      type: Object,
      required: false,
      default() {
        return {}
      },
    },
  },

  data() {
    return {
      textFilter: '',
      trackedSearches: new Set(),
      countryFilter: '',
      destinationPortSettings: null,
      showAwsTypeDetails: false,
      zoneFilter: null,
      loading: true,
      searchText: '',
    }
  },

  computed: {
    ...mapState('Company', { companyData: 'data' }),
    ...mapState('Marketplace', ['marketplaceData']),
    ...mapGetters('Auth', ['hasFeatureFlag']),
    ...mapGetters('Services', ['targetPorts', 'doesPortHaveUntaggedServices']),

    showNoTransitPortError() {
      return this.availableCompanies.length === 0 && this.destinationType === 'transit'
    },

    missingMpIcon() {
      const base = process.env.VUE_APP_PUBLIC_PATH === '/' ? '' : process.env.VUE_APP_PUBLIC_PATH

      return `${base}/fallback-images/mp-placeholder.png`
    },
    // If this page is directly accessed by clicking on a company tile
    // after doing a search in the quick connect panel in the services page,
    // we just show the company info of the selected company.
    // Here the user doesn't have the option to select a different provider.
    showCompany() {
      if (this.$route.query.companyUid) {
        return this.marketplaceData.find(company => company.companyUid === this.$route.query.companyUid)
      }

      return false
    },
    // keep a local state of the A-End port, this include the _location object.
    aEndPort() {
      if (!this.aEnd) return {}

      return this.targetPorts.find(port => port.productUid === this.aEnd.productUid) || {}
    },
    portObj() {
      // So we can collect information from the selected port.
      if (!this.destinationPortSettings) return {}

      // If the user is selecting a cloud service provider, then there could be two entries there with the
      // same productUid. The first one would be for a private connection and the second from the partner ports.
      // We therefore need to search the array in reverse.
      if (this.destinationType === 'cloud') {
        const reversedTargetPorts = [...this.targetPorts].reverse()

        return reversedTargetPorts.find(port => port.productUid === this.destinationPortSettings.productUid) || {}
      }

      return this.targetPorts.find(port => port.productUid === this.destinationPortSettings.productUid) || {}
    },
    companySelected() {
      if (!this.companySelectedUid && this.destinationPortSettings?.productUid) {
        const port = this.targetPorts.find(_port => _port.productUid === this.destinationPortSettings.productUid)

        if (port) this.$emit('update:companySelectedUid', port.companyUid)
      }

      const company = this.availableCompanies.find(_company => _company.companyUid === this.companySelectedUid)

      if (!company) return null

      // Keep a 0 or 1 in local storage to indicate which colour diversity ports should come first in the list (ENG-10206)
      const localStorageKey = 'diversity_order'

      let storedDiversityOrder = localStorage.getItem(localStorageKey)

      if (storedDiversityOrder === null) {
        storedDiversityOrder = Math.round(Math.random())

        localStorage.setItem(localStorageKey, storedDiversityOrder)
      } else {
        storedDiversityOrder = parseInt(storedDiversityOrder)
      }

      const filter = {
        portsFiltered: company.ports
          // Inject the configured diversity zone for ease of filtering, sorting, and display.
          .map(port => ({ ...port, diversityZone: port.diversityZone || port.config?.diversityZone }))
          .filter(port => this.checkAwsPortOk(company.companyUid, port) && this.checkPortCountryOk(port) && this.filterBySearchText(port) && this.checkPortZoneOk(port))
          .sort((a, b) => {
            // Sort first by title, then by location, and then by diversity zone.
            // By title - case insensitive
            const titleA = a.title.toLowerCase()
            const titleB = b.title.toLowerCase()

            if (titleA > titleB) return 1

            if (titleA < titleB) return -1

            // By location
            if (a._location?.formatted?.short && b._location?.formatted?.short) {
              if (a._location.formatted.short > b._location.formatted.short) return 1

              if (a._location.formatted.short < b._location.formatted.short) return -1
            }

            // By diversity zone
            if (a.diversityZone && b.diversityZone) {
              if (a.diversityZone === this.G_DIVERSITY_ZONE_BLUE && b.diversityZone === this.G_DIVERSITY_ZONE_RED) {
                return storedDiversityOrder ? 1 /* Red First */ : -1 /* Blue First */
              }
              if (a.diversityZone === this.G_DIVERSITY_ZONE_RED && b.diversityZone === this.G_DIVERSITY_ZONE_BLUE) {
                return storedDiversityOrder ? -1 /* Red First */ : 1 /* Blue First */
              }
            }

            return 0
          }),
      }

      return Object.assign(company, filter)
    },
    countries() {
      if (this.companySelected?.ports) {
        const ports = this.companySelected.ports.filter(port => this.checkAwsPortOk(this.companySelectedUid, port))

        return [...new Set(ports.map(_port => (_port._location?.country ? _port._location.country : '')))].filter(item => item !== '').sort()
      }

      return []
    },
    showZoneFilter() {
      if (this.companySelected?.ports) {
        const diversityZones = this.companySelected.ports
          .filter(port => this.checkAwsPortOk(this.companySelectedUid, port))
          .map(port => port.diversityZone || port.config?.diversityZone)

        return diversityZones.includes(this.G_DIVERSITY_ZONE_RED) && diversityZones.includes(this.G_DIVERSITY_ZONE_BLUE)
      }

      return false
    },
    // TODO: Review the need for the following block. No item in CLOUD_ITEMS has a connectType of value 'DEFAULT'.
    /***** Block Start ******/
    defaultCloudCompanyUids() {
      return CLOUD_ITEMS.filter(item => item.connectType === 'DEFAULT')
        .map(item => item.companyUids)
        .flat()
    },
    /***** Block End ******/
    filteredPorts() {
      // For cloud companies we get the cloud ports represented twice (once with the cloud connectType and once with the default connectType)
      // but when creating a private connection we need to make sure that neither of these ports gets listed, so build up a list of the
      // productUid entries for everything that has a listing as a non-standard connectType, and exclude all instances of port data that
      // includes those UIDs.
      const nonStandardConnectionPortUids = new Set()

      for (const port of this.targetPorts) {
        if (!['DEFAULT', 'VROUTER', 'MVE'].includes(port.connectType)) {
          nonStandardConnectionPortUids.add(port.productUid)
        }
      }


      return this.targetPorts.filter(port => {
        // Can't connect a port to itself
        if (port.productUid === this.aEnd.productUid) return false

        // Set in the data to be hidden
        if (port.connectType === 'HIDE') return false


        if (this.destinationType === 'transit') {
          if (port.connectType !== 'TRANSIT') return false
          const differentMarket = port._location?.market !== this.aEndPort?._location?.market
          // Non-MVE transit connections can only be made to the same market (when FF is off)
          if (!this.hasFeatureFlag('mve_country_internet_restriction')) {
            if (this.aEndPort.productType !== this.G_PRODUCT_TYPE_MVE && differentMarket) return false
          } else if (differentMarket) {
            return false
          }
        }

        if (this.destinationType === 'mx') {
          // FranceIX has its own connect type so display it for marketplace connections
          // VROUTER refers to MCRs which should be displayed
          if (!['DEFAULT', 'MVE', 'FRANCEIX', 'VROUTER'].includes(port.connectType) || this.defaultCloudCompanyUids.includes(port.companyUid)) {
            return false
          }
        }

        if (this.destinationType === 'cloud' && port.connectType === 'VROUTER') {
          return false
        }

        if (this.destinationType === 'cloud') {
          // Whitelist all the providers we know how to handle. This means that if someone adds a new connection type in the data
          // we will ignore it unless it's explicitly handled here.
          const whitelistedCSPs = ['AWS', 'AWSHC', 'GOOGLE', 'ALIBABA', 'AZURE', 'GCI', 'ORACLE', 'SFDC', 'NUTANIX', 'WASABI', 'OUTSCALE', 'IBM']
          // Add AMS-IX if enabled
          if (!this.disabledFeatures.productAMSIX) {
            whitelistedCSPs.push('AMSIX')
          }

          // We also want to allow any through where the port's companyUid matches one on the quickConnect list (CLOUD_ITEMS)
          if (!this.defaultCloudCompanyUids.includes(port.companyUid) && !whitelistedCSPs.includes(port.connectType)) {
            if (!whitelistedCSPs.includes(port.connectType) && !['DEFAULT', 'VROUTER', 'MVE', 'TRANSIT', 'FRANCEIX'].includes(port.connectType)) {
              console.info(`Ignoring unknown cloud provider ${port.connectType}`)
            }

            return false
          }
        }

        if (this.destinationType === 'private' && (port.companyUid !== this.companyData.companyUid || nonStandardConnectionPortUids.has(port.productUid))) {
          return false // Private connections are by definition to your own company, and are standard connect types
        }

        if (port.aggregationId && port.lagPrimary === false) {
          return false // Can't make connections on LAGs to anything except the lag primary.
        }

        if (this.textFilter && !port.companyName?.toLowerCase().includes(this.textFilter?.toLowerCase())) {
          return false
        }

        return true
      })
    },
    /**
     * Filter companies that match the Connect type.
     */
    availableCompanies() {
      // Generate a list of unique companies from all the ports.
      const uniqueCompaniesMap = new Map()
      const companyImageAPI = this.$appConfiguration.marketplaceMediaUrl

      for (const port of this.filteredPorts) {
        // For cloud companies, we want to add the first company id since it will be the primary
        const cloudCompany = CLOUD_ITEMS.find(item => item.companyUids.includes(port.companyUid))
        let activeCompanyUid = port.companyUid
        let activeCompanyName = port.companyName
        if (cloudCompany) {
          activeCompanyUid = cloudCompany.companyUids[0]
          activeCompanyName = cloudCompany.title
        }
        uniqueCompaniesMap.set(activeCompanyUid, {
          companyUid: activeCompanyUid,
          companyName: activeCompanyName,
          companyImage: `${companyImageAPI}/${activeCompanyUid}`,
          ports: [],
        })
      }

      // Special handling for SAP because it doesn't have any ports associated with it.
      if (this.destinationType === 'cloud' && (!this.textFilter || 'sap'.includes(this.textFilter.toLowerCase()))) {
        uniqueCompaniesMap.set(SAP_DETAILS.companyUid, {
          companyUid: SAP_DETAILS.companyUid,
          companyName: SAP_DETAILS.companyName,
          companyImage: `${companyImageAPI}/${SAP_DETAILS.companyUid}`,
          ports: [],
        })
      }
      // Special handling for WASABI because it doesn't have any ports associated with it.
      if (this.destinationType === 'cloud' && (!this.textFilter || 'wasabi'.includes(this.textFilter.toLowerCase()))) {
        uniqueCompaniesMap.set(WASABI_DETAILS.companyUid, {
          companyUid: WASABI_DETAILS.companyUid,
          companyName: WASABI_DETAILS.companyName,
          companyImage: `${companyImageAPI}/${WASABI_DETAILS.companyUid}`,
          ports: [],
        })
      }

      // Enhance the companies with the available ports for that company
      for (const port of this.filteredPorts) {
        let portOk = true

        if (port.vxcPermitted === false || this.doesPortHaveUntaggedServices(port)) {
          portOk = false
        }

        if (portOk) {
          // Need to target the correct companyUid
          const cloudCompany = CLOUD_ITEMS.find(item => item.companyUids.includes(port.companyUid))
          let activeCompanyUid = port.companyUid
          if (cloudCompany) {
            activeCompanyUid = cloudCompany.companyUids[0]
          }
          // Append the port
          uniqueCompaniesMap.get(activeCompanyUid).ports.push(port)
        }
      }

      const sortedCompanies = Array.from(uniqueCompaniesMap.values()).sort((a, b) => {
        if (a.companyName > b.companyName) return 1
        if (a.companyName < b.companyName) return -1
        return 0
      })

      return sortedCompanies
    },

    isTransitOrPrivateConnection() {
      return (this.destinationType === 'private' || this.destinationType === 'transit' && this.availableCompanies.length === 1)
    },
    companyConnectType() {
      // Special handling for SAP since it has no ports
      if (this.companySelected?.companyUid === SAP_DETAILS.companyUid) {
        return SAP_DETAILS.connectType
      }
      if (this.companySelected?.companyUid === WASABI_DETAILS.companyUid && this.destinationType === 'cloud') {
        return WASABI_DETAILS.connectType
      }
      if (this.companySelected?.ports.length > 0 && this.companySelected.ports[0].connectType) {
        return this.companySelected.ports[0].connectType
      }
      return false
    },
    showPortSelectList() {
      if (this.loading) return true

      // Hide the port select list
      if (
        (['AZURE', 'ORACLE', 'GCI', 'WASABI', 'GOOGLE', 'NUTANIX', 'SAP'].includes(this.companyConnectType)) ||
        (this.showAwsHeader && (this.showAwsTypeDetails || !this.destinationPortSettings?.awsType))
      ) {
        return false
      }

      return true
    },
    showAwsHeader() {
      return this.companySelectedUid === AWS_COMPANY
    },
    portSelectHeight() {
      if (this.showAwsHeader) {
        return this.destinationPortSettings.awsType === 'AWS' ? 314 : 273
      }

      return 450
    },
  },

  watch: {
    /**
     * If the user chooses a different connection type then wipe out the partner config settings,
     * since they won't be relevant.
     *
     * This also handles fetching fixed bandwidths from the api for certain companies
     * For companies that don't require a serviceKey or equivalent, see the template above for example oracle-key
     */
    'portObj.connectType': {
      immediate: true,

      async handler(newConnectType) {
        // Reset partnerConfig on connectType Change
        const partnerConfig = this.destinationPortSettings?.partnerConfig

        if (partnerConfig && partnerConfig.connectType !== newConnectType && !['SAP', 'WASABI'].includes(this.companyConnectType)) {
          let fixedBandwidths = null

          if (['AWSHC', 'ALIBABA'].includes(newConnectType)) {
            await sdk.instance
              .lists(newConnectType.toLocaleLowerCase())
              .then(data => {
                fixedBandwidths = data.bandwidths
              })
          }

          this.destinationPortSettings.partnerConfig = {
            connectType: newConnectType,
            complete: false,
            fixedBandwidths,
          }
        }
      },
    },
    companySelectedUid: {
      immediate: true,
      handler() {
        this.countryFilter = ''
        this.zoneFilter = null
        if (this.companySelectedUid === AWS_COMPANY) {
          this.watchAwsOptionClick()
        } else {
          this.dontWatchAwsOptionClick()
        }
      },
    },
    /**
     * On created, we have cloned the B-End into the destinationPortSettings,
     * and when they change we will emit them as an update to the B-End to be handled by CreateConnection.
     */
    destinationPortSettings: {
      deep: true,
      handler(newValue) {
        this.$emit('update:bEnd', newValue)
      },
    },
    showPortSelectList: {
      handler(newValue) {
        if (newValue) {
          this.showSelectedPort()
        }
      },
    },
  },

  created() {
    this.destinationPortSettings = deepClone(this.bEnd)
    if (this.isTransitOrPrivateConnection) this.$emit('update:companySelectedUid', this.availableCompanies[0].companyUid)

    if (!this.companySelectedUid) {
      // If we came in here directly, we need to ensure that the underlying createConnection knows which company
      // is selected.
      if (this.$route.query.companyUid) {
        this.$emit('update:companySelectedUid', this.$route.query.companyUid)
      }

      if (this.$route.query.cloud) {
        this.$emit('update:companySelectedUid', this.$route.query.cloud)
      }
    }
  },

  mounted() {
    this.$nextTick(() => {
      // If the company is already selected, try to show it in the company table
      if (this.companySelectedUid && this.$refs.providerSelectTable) {
        const index = this.availableCompanies.findIndex(company => {
          return company.companyUid === this.companySelectedUid
        })
        if (index !== -1) {
          // Set the current row
          this.$refs.providerSelectTable.setCurrentRow(this.availableCompanies[index])

          // Scroll the selected row to visible - nasty hacky way to do it, but it works
          const targetTop = this.$refs.providerSelectTable.$el.querySelectorAll('.el-table__body tr')[index].getBoundingClientRect().top
          const containerTop = this.$refs.providerSelectTable.$el.querySelector('.el-table__body').getBoundingClientRect().top
          const scrollParent = this.$refs.providerSelectTable.$el.querySelector('.el-table__body-wrapper')
          scrollParent.scrollTop = targetTop - containerTop
        }
      }

      // If an IBM port is already selected, get the filtered list of bandwidths
      const ibmCloudItem = CLOUD_ITEMS.find(item => item.title === 'IBM Cloud')
      if (
        ibmCloudItem.companyUids.includes(this.companySelectedUid) &&
        this.destinationPortSettings.productUid &&
        this.$refs.portSelectTable &&
        this.destinationType === 'cloud'
      ) {
        this.getIbmBandwidths(this.destinationPortSettings.productUid)
      }

      // See if we already have a configuration of which type of AWS connection
      if (this.destinationPortSettings?.partnerConfig) {
        if (this.destinationPortSettings.partnerConfig.connectType === 'AWS') {
          this.destinationPortSettings.awsType = 'AWS'
        } else if (this.destinationPortSettings.partnerConfig.connectType === 'AWSHC') {
          this.destinationPortSettings.awsType = 'AWSHC'
        }
      }
      // If we are creating an AWS connection and have not selected either type yet, show the info
      if (['AWS', 'AWSHC'].includes(this.companyConnectType) && !this.destinationPortSettings.awsType && !localStorage.getItem('hideAWSDescription')) {
        this.showAwsTypeDetails = true
      }

      this.showSelectedPort()

      // Change zones to fix table rows not being selectable in some rare cases
      this.zoneFilter = this.G_DIVERSITY_ZONE_RED
      this.zoneFilter = null

      this.loading = false
    })
  },

  beforeDestroy() {
    this.dontWatchAwsOptionClick()
  },

  methods: {
    ...mapMutations('Notifications', ['notifyBad']),
    slug,
    setFallbackImage,
    watchAwsOptionClick() {
      // Set up a click handler on the radio buttons so we can hide the information on click even if it's already
      // selected. This needs to be delayed because the element will only be generated on next render
      this.$nextTick(() => {
        this.$refs.awsType?.$el.addEventListener('click', this.awsOptionClicked)
      })
    },
    dontWatchAwsOptionClick() {
      this.$refs.awsType?.$el.removeEventListener('click', this.awsOptionClicked)
    },
    toggleAwsInfoPanel() {
      if (this.showAwsTypeDetails) {
        this.showAwsTypeDetails = false

        localStorage.setItem('hideAWSDescription', 'true')
      } else {
        this.destinationPortSettings.awsType = null
        this.destinationPortSettings.productUid = null

        this.$nextTick(() => {
          this.$refs.awsTypeFormItem.clearValidate()
        })

        this.showAwsTypeDetails = true

        localStorage.removeItem('hideAWSDescription')
      }
    },
    /**
     * Compute the number of ports/destinations for a given provider to show on the left hand side.
     * @param {string} companyUid The company UID to check for AWS.
     * @param {Array<Object>} ports The list of ports to count.
     * @returns {string} The translation string including the number of ports.
     */
    providerPortCountString(companyUid, ports) {
      if (companyUid === AWS_COMPANY) {
        let awsCount = 0
        let awshcCount = 0

        for (const port of ports) {
          switch (port.connectType) {
            case 'AWS':
              awsCount++
              break
            case 'AWSHC':
              awshcCount++
              break
            default:
              captureSentryError(new Error(`Invalid connectType: ${port.connectType}`))
              break
          }
        }

        const awsString = this.$tc('ports.ports-type-count', awsCount, {
          count: awsCount,
          type: this.$t('aws.hosted-vif'),
        })

        const awshcString = this.$tc('ports.ports-type-count', awshcCount, {
          count: awshcCount,
          type: this.$t('aws.hosted-connection'),
        })

        return `${awsString}<br/>${awshcString}`
      }

      const countType = this.destinationType === 'mx' ? 'marketplace.destinations-count' : 'ports.ports-count'
      return this.$tc(countType, ports.length, {
        count: ports.length,
      })
    },
    resetSelectedPortOnAwsTypeChange() {
      // trigger validation as no port selected when aws connect type change
      this.destinationPortSettings.productUid = null
    },
    showSelectedPort() {
      this.$nextTick(() => {
        if (this.destinationPortSettings?.productUid && this.$refs.portSelectTable) {
          const index = this.companySelected.portsFiltered.findIndex(port => port.productUid === this.destinationPortSettings?.productUid)

          if (index !== -1) {
            // Set the current row
            this.$refs.portSelectTable.setCurrentRow(this.companySelected.portsFiltered[index])

            // Scroll the selected row to visible - nasty hacky way to do it, but it works
            const targetTop = this.$refs.portSelectTable.$el.querySelectorAll('.el-table__body tr')[index].getBoundingClientRect().top
            const containerTop = this.$refs.portSelectTable.$el.querySelector('.el-table__body').getBoundingClientRect().top
            const scrollParent = this.$refs.portSelectTable.$el.querySelector('.el-table__body-wrapper')

            scrollParent.scrollTop = targetTop - containerTop
          }
        }
      })
    },
    // So we can hide the details on click of an option even if it's already selected
    awsOptionClicked() {
      this.showAwsTypeDetails = false
    },
    /**
     * All the connection type specific input panels have the opportunity to update various settings, so
     * alter behaviour depending on what is sent through by the input type.
     */
    update(event) {
      if (event.vlan) {
        this.destinationPortSettings.vlan = event.vlan
      }

      if (event.partnerConfig) {
        // Combine any new partner config settings with what was already collected
        this.destinationPortSettings.partnerConfig = Object.assign({}, this.destinationPortSettings.partnerConfig, event.partnerConfig)
      }

      if (typeof event.productUid !== undefined) {
        this.destinationPortSettings.productUid = event.productUid
      }

      if (typeof event.showAllPorts === 'boolean') {
        this.$emit('update:azureShowAllPorts', event.showAllPorts)
      }
    },
    /**
     * Handle row click on provider selection table (left side of page)
     * @param {Object} newValue New value from row selected
     */
    handleProviderSelect(newValue) {
      if (this.companySelected?.companyUid === newValue.companyUid) {
        this.$refs.providerSelectTable.setCurrentRow()
        this.$emit('update:companySelectedUid', null)
      } else {
        this.$emit('update:companySelectedUid', newValue.companyUid)
      }

      this.destinationPortSettings.productUid = null
      this.destinationPortSettings.awsType = null
      this.destinationPortSettings.vlan = null

      this.showAwsTypeDetails = localStorage.getItem('hideAWSDescription') ? false : true
    },
    /**
     * Handle row click on destination port selection table (right side of page)
     * @param {Object} newValue New value from row selected
     */
    handlePortSelect(newValue) {
      // If nothing was selected or the same item as last time was clicked, then deselect.
      if (!newValue || (this.destinationPortSettings.productUid && this.destinationPortSettings.productUid === newValue.productUid)) {
        this.$refs.portSelectTable?.setCurrentRow()

        this.destinationPortSettings.productUid = null
      } else {
        this.destinationPortSettings.productUid = newValue.productUid
      }

      /**
       * This handles fetching bandwidth data for IBM direct link
       * Since it can be different per destination port, we call API upon every click.
       */
      if (this.companyConnectType === 'IBM') {
        this.$nextTick(() => {
          this.getIbmBandwidths(newValue.productUid)
        })
      }
    },
    /**
     * Get a list of available bandwidths per IBM port for selection in next step
     */
    getIbmBandwidths(productUid) {
      sdk.instance
        .product(productUid)
        .ibm()
        .then(data => {
          // Update bandwidths with values from API
          this.destinationPortSettings.partnerConfig.fixedBandwidths = data.bandwidths
        })
        .catch(error => {
          // Deselect row if API call fails to prevent user from moving forward
          this.$refs.portSelectTable?.setCurrentRow()
          this.destinationPortSettings.productUid = null
          // Show error message to user
          const errorStr = error.data?.message ?? error
          this.notifyBad({
            title: this.$t('services.ibm-request-fail'),
            message: errorStr,
          })
        })
    },
    trackSearch() {
      if (this.textFilter && !this.trackedSearches.has(this.textFilter)) {
        captureEvent(`create-vxc.${this.destinationType}.select-port.search`, { query: this.textFilter })
        this.trackedSearches.add(this.textFilter)
      }
    },
    checkPortCountryOk(port) {
      if (this.countryFilter) {
        return port._location?.country === this.countryFilter
      }

      return true
    },
    filterBySearchText(port) {
      if (this.searchText) {
        return JSON.stringify([port.productName, port.locationDetail]).toLocaleLowerCase().includes(this.searchText.toLocaleLowerCase())
      }

      return true
    },
    checkPortZoneOk(port) {
      // Filter by zone if required
      if (this.zoneFilter) {
        return port.diversityZone === this.zoneFilter
      }

      return true
    },
    checkAwsPortOk(companyUid, port) {
      // Special handling for AWS ports
      if (companyUid === AWS_COMPANY && this.destinationPortSettings?.awsType) {
        return port.connectType === this.destinationPortSettings.awsType
      }

      return true
    },
    /**
     * Determine the icon to display for the B-End destination.
     * @param {Object} destination Information about the B-End service.
     */
    iconName(destination) {
      if (this.destinationType === 'mx') return 'vxc'

      let result = this.G_PRODUCT_TYPE_MEGAPORT

      if (destination.productType === this.G_PRODUCT_TYPE_MVE) {
        result = this.G_PRODUCT_TYPE_MVE
      } else if (destination.productType === this.G_PRODUCT_TYPE_MCR2) {
        result = 'MCR'
      } else if (destination.aggregationId || destination.lagPortCount) {
        result = 'LAG'
      }

      return result || ''
    },
  },
}
</script>

<style lang="scss" scoped>
.expand-arrow {
  transition: transform 0.3s;
  &.active {
    transform: rotate(180deg);
  }
  &:focus {
    outline: none;
  }
}

.info-button {
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
}
.types-details {
  height: 380px;
  overflow-y: scroll;
}
.content-column {
  padding: 1rem;
  box-sizing: border-box;
  display: flex;
  width: min-content;
  min-width: 430px;
}
.content-column > div {
  flex: 1 1 auto;
}
.provider-select-holder,
.port-select-holder {
  background-color: #fbfbfb;
  border: 1px solid var(--card-border-color);
  border-radius: var(--border-radius-base);
  padding: 0.5rem;
  margin-top: 1rem;
  clear: both;
}
.is-error .provider-select-holder,
.is-error .port-select-holder {
  border-color: var(--color-danger);
}
.is-success .provider-select-holder,
.is-success .port-select-holder {
  border-color: var(--color-success);
}
svg.icon {
  display: inline-block;
  color: var(--color-text-regular);
  width: 2.5rem;
  height: 2.5rem;
  fill: currentColor;
}
.port-cell svg {
  margin-right: 0.5rem;
}

.width-50 {
  &-pc {
    width: 50%;
  }
  &px {
    width: 50px;
  }
}

.ml-25-pc {
  margin-left: 25%;
}
</style>

<style lang="scss">
.provider-select-table table tr.provider-row,
.port-select-table table tr.port-row {
  background-color: #fbfbfb;
  &:hover > td {
    background-color: #fbfbfb;
  }
}

.provider-select-table.el-table td,
.port-select-table.el-table td {
  padding: 0;
  border: 0;
}

.port-select-table.el-table th {
  padding: 0;
  margin: 0;
  line-height: initial;
  font-weight: 300;
  background-color: transparent;
}

.provider-select-table .provider-cell,
.port-select-table .port-cell {
  display: flex;
  align-items: center;
  background-color: var(--color-white);
  border: 1px solid var(--card-border-color);
  border-radius: var(--border-radius-base);
  padding: 0.5rem;
  margin: 0.25rem 0;
  cursor: pointer;
}
.provider-cell,
.port-cell {
  background-color: var(--color-white);
  word-break: normal;
  word-wrap: break-word;
  &:hover {
    border-color: var(--color-primary-light-5);
    background-color: var(--color-primary-light-9);
  }
  .provider-header,
  .port-header {
    font-weight: 700;
  }
}

.current-row .provider-cell,
.current-row .port-cell {
  .provider-header,
  .port-header {
    color: var(--color-primary);
  }
  border-color: var(--color-primary);
  background-color: var(--color-primary-light-8);
  svg.icon {
    color: var(--color-primary);
    fill: currentColor;
  }
}
.provider-select-table .el-table__body tr.current-row > td,
.port-select-table .el-table__body tr.current-row > td {
  background-color: #fbfbfb;
}

.zone-table-header {
  justify-content: flex-end;
  > div {
    margin-left: 5px;
  }
  color: var(--color-text-regular);
  font-weight: 400;
}
.dz-dot.el-radio-button--mini .el-radio-button__inner {
  padding: 4px 5px 2px 10px;
}
.diversity-icon {
  font-size: 2rem;
  margin-right: 0.5rem;
}

.types-details li {
  color: var(--color-text-regular);
  font-weight: 300;
}

.port-select-table .el-table__empty-text {
  padding: 1.5rem;
  line-height: 1.5;
}
</style>
